(function () {
  const replaceState = history.replaceState;

  history.replaceState = function () {
    var hash = window.location.hash;

    if (arguments[2].indexOf('#/shade') !== -1 && hash.indexOf('#/shade') === -1 && $(hash).offset()) {
      scrollTo({ top: $(hash).offset().top });
    }
    replaceState.apply(history, arguments);
  };
})();
